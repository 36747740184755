import {
  getStoryblokApi,
  StoryblokComponent,
  StoryData,
  StoryParams,
  useStoryblokState,
} from '@storyblok/react';
import type { GetStaticPropsContext } from 'next';

interface Props {
  story: StoryData;
  preview: boolean;
  storyblokParams: StoryParams;
  locale: string;
}

export default function CoverGirl(props: Props) {
  const { story, preview } = props;
  const editableStory: StoryData = useStoryblokState(story, {}, preview);

  return (
    <>
      <StoryblokComponent blok={editableStory.content} />
    </>
  );
}

export async function getStaticProps({ preview = false, locale }: GetStaticPropsContext) {
  const storyblokSlug = 'cover-girl';

  const storyblokParams = {
    version: preview ? 'draft' : 'published',
    language: locale,
  };

  const storyblokApi = getStoryblokApi();
  const { data } = await storyblokApi.get(`cdn/stories/${storyblokSlug}`, storyblokParams);

  return {
    props: {
      story: data ? data.story : false,
      key: data ? data.story.id : false,
      preview: preview,
      locale: locale,
      messages: {
        ...require(`../messages/global/${locale}.json`),
      },
    },
    revalidate: 3600, // revalidate every hour
  };
}
